import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import ContactPageTemplate from './contact-page-template'

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <Seo title='Moolah - Contact Us' />
      <ContactPageTemplate
        email={frontmatter.email}
        phone_number={frontmatter.phone_number}
        address={frontmatter.address}
        location={frontmatter.location}
      />
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        email
        phone_number
        address
        location
      }
    }
  }
`
