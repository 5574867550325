import GoogleMapReact from 'google-map-react'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Section from '../components/Section'
import * as styles from './contact-page-template.module.scss'

const ContactPageTemplate = ({ email, phone_number, address, location }) => {
  const [map, setMap] = useState()
  const [maps, setMaps] = useState()

  const mapMarker = useRef()

  const coords = JSON.parse(location).coordinates
  const lng = coords[0]
  const lat = coords[1]

  useEffect(() => {
    if (maps && map) {
      const coords = JSON.parse(location).coordinates

      if (mapMarker.current) {
        mapMarker.current.setMap(null)
      }

      const _marker = new maps.Marker({
        position: { lng: coords[0], lat: coords[1] },
        map,
        title: 'Hoolah',
      })

      mapMarker.current = _marker

      const latLng = new maps.LatLng(coords[1], coords[0])
      map.panTo(latLng)
    }
  }, [location, maps, map])

  const googleMapsApiLoaded = (map, maps) => {
    mapMarker.current = new maps.Marker({
      position: { lng: coords[0], lat: coords[1] },
      map,
      title: 'Hoolah',
    })

    setMap(map)
    setMaps(maps)
  }

  const defaultProps = {
    center: {
      lng: lng,
      lat: lat,
    },
    zoom: 11,
  }

  return (
    <>
      <Section
        style={{
          width: '100vw',
          background: 'var(--gradient-purple)',
          color: 'white',
        }}>
        <Container>
          <h1>Contact Us</h1>
        </Container>
      </Section>
      <Row className='flex-grow-1'>
        <Col md={6} className='order-1 order-md-2'>
          <Section>
            <h2 className='mb-4'>Get in Touch</h2>
            {email && (
              <p>
                <a href={`mailto:${email}`}>{email}</a>
              </p>
            )}
            {phone_number && (
              <p>
                <a href={`tel:${phone_number}`}>{phone_number}</a>
              </p>
            )}
            <p>
              {address.split('\n').map(function (item, idx) {
                return (
                  <span key={idx}>
                    {item}
                    <br />
                  </span>
                )
              })}
            </p>
          </Section>
        </Col>

        <Col md={6} className={`${styles.map} order-2 order-md-1`}>
          <div className='h-100 w-100'>
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_API_KEY }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              onGoogleApiLoaded={({ map, maps }) => googleMapsApiLoaded(map, maps)}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

ContactPageTemplate.propTypes = {
  email: PropTypes.string,
  phone_number: PropTypes.string,
  address: PropTypes.string,
  location: PropTypes.shape({
    type: PropTypes.string,
    coordinates: PropTypes.string,
  }),
}

export default ContactPageTemplate
